import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from "@mui/material/colors";

import { strDateITAfromDayJs, strDateUSAfromDayJs, strDateITAfromStrUSA, strDateUSAfromStrITA } from '../../../utils/dateUtils.js'
const { strCurrency, strEuroCurrency } = require('../../../utils/currencyUtils.js')

const EditMessagePageComponent = ({ updateMessageApiRequest, fetchMessage }) => {

  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState([]);
  const [updateMessageResponseState, setUpdateMessageResponseState] = useState({ message: "", error: "" });

  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [telephone, setTelephone] = useState("");                      
  const [messageTxt, setMessageTxt] = useState("");                      
  const [note, setNote] = useState("");                      
  const [day, setDay] = useState("");                      
  const [time, setTime] = useState("");                      

  useEffect(() => {
    fetchMessage(id)
    .then(data => {
      console.log(`data =`, data);  
      setTitle(data?.title);
      setEmail(data?.email);
      setTelephone(data?.telephone);
      setMessageTxt(data?.messageTxt);
      setNote(data?.note);
      setDay(data?.day);
      setTime(data?.time);
      console.log(`title  =`, title);  
      console.log(`message =`, message);  
    })
    .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));         
},[id])

function salvaMessageModificata (eventKey) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  console.log(`title dig =`, title);  
  console.log(`email dig =`, email);  
  console.log(`telephone dig =`, telephone);  
  console.log(`messageTxt dig =`, messageTxt);  

  updateMessageApiRequest(id, title,  email, telephone, messageTxt, note, day, time)
  .then(data => {
      if (data.success === "message updated") {
          navigate("/admin/messages");
      }
    })
  .catch(er => {
      setUpdateMessageResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
  })

  window.location.assign('/admin/messages')
}

function gotoIndietro (eventKey) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  window.location.assign('/admin/messages')
}


  return (
    <Container fullWidth>

      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Dettagli messaggio</h1>

          <Form noValidate validated={validated} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-cliente" label="Nome Cognome" variant="outlined" value={title} defaultValue={title}
                style={{
                  width: "430px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
              />

              <TextField id="id-email" label="Email" variant="outlined" value={email} defaultValue={email} 
                style={{
                  width: "400px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />

              <TextField id="id-telephone" label="Telefono" variant="outlined" value={telephone} defaultValue={telephone} 
                style={{
                  width: "200px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setTelephone(event.target.value);
                }}
              />

              <TextField id="id-messageTxt" label="Messaggio" variant="outlined" value={messageTxt} defaultValue={messageTxt} 
                style={{
                  width: "800px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                multiline
                rows={3}
                maxRows={6}
                onChange={(event) => {
                  setMessageTxt(event.target.value);
                }}
              />

              <TextField id="id-day" label="Data" variant="outlined" value={strDateITAfromStrUSA(day)} defaultValue={day} 
                style={{
                  width: "130px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setDay(event.target.value);
                }}
              />

              <TextField id="id-time" label="Ora" variant="outlined" value={time} defaultValue={time} 
                style={{
                  width: "100px",
                }}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(event) => {
                  setTime(event.target.value);
                }}
              />
           
            </Box>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
        <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => { gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>
  );
};

export default EditMessagePageComponent;
