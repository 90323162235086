import CreateMessagePageComponent from "./components/CreateMessagePageComponent";
import axios from "axios";
import { useSelector } from "react-redux";
import { newMessage,deleteMessage, saveAttributeToCatDoc } from "../../redux/actions/messageActions";
import { useDispatch } from "react-redux";

const createMessageApiRequest = async (formInputs) => {
  const { data } = await axios.post(`/api/messages`, { ...formInputs });
  return data;
};

const AdminCreateMessagePage = () => {
  const { messages } = useSelector((state) => state.getCategories);
  const dispatch = useDispatch();

  return (
    <CreateMessagePageComponent
      createMessageApiRequest={createMessageApiRequest}
      messages={messages}
      reduxDispatch={dispatch}
      newMessage={newMessage}
      deleteMessage={deleteMessage}
      saveAttributeToCatDoc={saveAttributeToCatDoc}
    />
  );
};

export default AdminCreateMessagePage;
