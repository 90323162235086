import AdminMessagesPageComponent from "./components/AdminMessagesPageComponent";
import axios from "axios";

const fetchMessages = async (abctrl) => {
    const {data} = await axios.get("/api/messages", {
        signal: abctrl.signal,
    });
    return data
}

const deleteMessage = async (userId) => {
    const { data } = await axios.delete(`/api/messages/${userId}`);
    return data
}

const AdminMessagesPage = () => {
  return <AdminMessagesPageComponent fetchMessages={fetchMessages} deleteMessage={deleteMessage} />;
};

export default AdminMessagesPage;