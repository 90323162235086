import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import * as React from 'react';
import axios from "axios";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs'
import isEmail from 'validator/lib/isEmail';
import isStrongPassword from 'validator/lib/isStrongPassword';
import isLength from 'validator/lib/isLength';
import equals from 'validator/lib/equals';

const ContattiPageComponent = ({ loginUserApiRequest,reduxDispatch, setReduxUserState  }) => {
  const [validated, setValidated] = useState(false);
  const [loginUserResponseState, setLoginUserResponseState] = useState({
    success: "",
    error: "",
    loading: false,
  });

  const navigate = useNavigate();

  const [name, setName] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [phone, setPhone] = useState("");                      
  const [message, setMessage] = useState("");                       

  const [fieldNameError, setFieldNameError] = useState(false);
  const [fieldLastnameError, setFieldLastnameError] = useState(false);
  const [fieldEmailError, setEmailFieldError] = useState(false);
  const [fieldPasswordError, setFieldPasswordError] = useState(false);

  const [showAlertMessaggioInviato, setShowAlertMessaggioInviato] = useState(false);

  const config = require("../../constants/constants.js")
   
  const [page, setPage] = useState({});  
  const [id, setId] = useState("");                      
  const [title, setTitle] = useState("");                      
  const [subTitle, setSubTitle] = useState("");     
  const [description, setDescription] = useState("");     
  const [customizedName, setCustomizedName] = useState("");     
  const [customizedStreet, setCustomizedStreet] = useState("");     
  const [customizedZip, setCustomizedZip] = useState("");     
  const [customizedCity, setCustomizedCity] = useState("");     
  const [customizedEmail, setCustomizedEmail] = useState("");     
  const [tabAttivo, setTabAttivo] = React.useState('home');
  const [pathImage1, setPathImage1] = useState("");     
  const [pathImage2, setPathImage2] = useState("");     
  const [pathImage3, setPathImage3] = useState("");     
  const [titleImage1, setTitleImage1] = useState("");     
  const [subTitleImage1, setSubTitleImage1] = useState("");     
  const [titleImage2, setTitleImage2] = useState("");     
  const [subTitleImage2, setSubTitleImage2] = useState("");     
  const [titleImage3, setTitleImage3] = useState("");     
  const [subTitleImage3, setSubTitleImage3] = useState("");     

  const getPageByMenuValueApiRequest = async (menuValue) => {
    console.log("[getPageByMenuValueApiRequest] leggo page con menuValue =",menuValue);
    const url = `/api/pages/menuValue/`+menuValue;
    const { data } = await axios.get(url);
    console.log(`data =`, data);  
    return data
  }

  const storeMessaggioApiRequest = async (formInputs) => {
    const { data } = await axios.post(`/api/messages`, { ...formInputs });
    return data;
  };
  
  const sendEmailContattiApiRequest = async (emailContattiForm) => {
    console.log(' [createEmailContattiApiRequest] emailContattiForm=',emailContattiForm);
    const { data } = await axios.post(`/api/messages/email`, { ...emailContattiForm });
    return data;
  };  

  useEffect(() => {
    getPageByMenuValueApiRequest('CONTATTI').then((data) => { 
      console.log(`data 1 =`, data);  
      setPage(data);
      setTitle(data?.title.text);
      setSubTitle(data?.subTitle.text);
      setDescription(data?.description.text);
      setTabAttivo('home');
      setId(data?._id);
      setPathImage1(data?.images[0].path);
      setPathImage2(data?.images[1].path);
      setPathImage3(data?.images[2].path);
      setCustomizedName(page?.customizedContent.name.text);
      setCustomizedStreet(page?.customizedContent.street.text);
      setCustomizedZip(page?.customizedContent.zip.text);
      setCustomizedCity(page?.customizedContent.city.text);
      setCustomizedEmail(page?.customizedContent.email.text);
      setTitleImage1(data?.imagesTitle[0].text);
      setSubTitleImage1(data?.imagesSubTitle[0].text);
      setTitleImage2(data?.imagesTitle[1].text);
      setSubTitleImage2(data?.imagesSubTitle[1].text);
      setTitleImage3(data?.imagesTitle[2].text);
      setSubTitleImage3(data?.imagesSubTitle[2].text);
      console.log(`id =`, id);  
      console.log(`page?.title.text =`, page?.title.text);  
      console.log(`page?.description.text =`, page?.description.text);  
      console.log(`page?.customizedContent =`, page?.customizedContent);  
      console.log(`page?.customizedContent.name.text =`, page?.customizedContent.zip.text);  
    })
    .catch((er) => {
      console.log(er);
    });  
  }, [tabAttivo,id])

  function inviaMessaggio (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    // -- persisto Messaggio ricevuto dal sito
    const formInputs = {
      title: name,
      email: email,
      telephone: phone,
      messageTxt: message,
      note: '',
      day: dayjs(new Date()).format('YYYY-MM-DD'),
      time: dayjs(new Date()).format('HH:mm:ss'),
    };
    storeMessaggioApiRequest(formInputs)

    // -- imvio email Messaggio ricevuto dal sito
    const emailMessageForm = 
    {
      title: name,
      email: email,
      telephone: phone,
      messageTxt: message,
      note: '',
      day: dayjs(new Date()).format('YYYY-MM-DD'),
      time: dayjs(new Date()).format('HH:mm:ss'),
      hostTransmitter: "smtp.hostinger.com",
      emailTransmitter: "support@mpmproject.it",
      pswTransmitter: "Supp10mpm!",
      // va cambiata con quella del cliente, lui riceve email per ciascuna richiesta di informazioni dal suo sito
      emailToSend: "info@mpmproject.it"
    };
    sendEmailContattiApiRequest(emailMessageForm);

    setShowAlertMessaggioInviato(true);

    //window.location.assign('/admin/users')
  }

  function closeAlertMessage () {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setShowAlertMessaggioInviato(false);
    //navigate(1);
  }
  
  return (

    <Container className="px-0">

    <Row
      g-0
      className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative"
    >

      <Col
        xs={{ order: 2 }}
        md={{ size: 4, order: 1 }}
        tag="aside"
        className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0"
      >

        <Carousel> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={pathImage1}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage1}</h3> 
              <p>{subTitleImage1}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={pathImage2}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage2}</h3> 
              <p>{subTitleImage2}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={pathImage3}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage3}</h3> 
              <p>{subTitleImage3}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
        </Carousel> 
      </Col>

      <Col
        xs={{ order: 1 }}
        md={{ size: 7, offset: 1 }}
        tag="section"
        className="py-5 mb-5 py-md-0 mb-md-0"
      >

        <h1>{title}</h1>

        <Form.Group className="mb-3" controlId="validationCustom01" >
            <Form.Label><em>&nbsp;{subTitle}</em></Form.Label>
        </Form.Group>

        <Box
          component="form"
          sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
          noValidate
          autoComplete="off"
        >

          <TextField id="id-name" label="nome e cognome" variant="outlined" defaultValue={name} value={name} required
            style={{
              width: "52ch",
            }}
            error={fieldNameError}
            onChange={(event) => {
              setName(event.target.value);
              if (isLength(event.target.value,{ min: 3, max:15})) {
                setFieldNameError(false);
              } else {
                setFieldNameError(true);
              }   
            }}
          />

          <TextField id="id-email" label="email" variant="outlined" defaultValue={email} value={email} required
            style={{
              width: "32ch",
            }}
            error={fieldEmailError}
            onChange={(event) => {
              setEmail(event.target.value);
              if (isEmail(event.target.value)) {
                  setEmailFieldError(false);
                } else {
                  setEmailFieldError(true);
                }   
            }}
          />

          <TextField id="id-phone" label="telefono" variant="outlined" defaultValue={phone} value={phone}
            style={{
              width: "18ch",
            }}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />

          <TextField id="id-message" label="messaggio" variant="outlined" defaultValue={message} value={message} required
            style={{
              width: "52ch",
            }}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            multiline
            rows={3}
            maxRows={6}
          />

        </Box>

        <Button 
          type="button"
          className="btn btn-primary"
          onClick={(e) => { inviaMessaggio(e); }}
          style={{
            width: "80px",
            display: showAlertMessaggioInviato ? "none" : "block"  
          }}
        >
          Invia
        </Button>             

      </Col>
    </Row>
    

    <Alert
      show={showAlertMessaggioInviato}
      variant="success"
      dismissible
      onClose={() => closeAlertMessage() }
    >              
      <Row className="justify-content-right">
        <Col md={4}>
        </Col>
        <Col md={8}>
          <Typography sx={{ color: '#1136a0', fontSize: 16 }}>
            <Box fontWeight='fontWeightMedium' display='inline' sx={{ fontWeight: 'bold' }} >
              Messaggio inviato:
            </Box>
            &nbsp;grazie per averci contattato, risponderemo al più presto 
          </Typography>
          <Typography>
          </Typography>
        </Col>
    </Row>
  </Alert>

    <Row className="mt-5 justify-content-md-center">
      <Col md={6}>
      </Col>
    </Row>

    </Container>
  );
};

export default ContattiPageComponent;
