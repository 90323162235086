import * as actionTypes from "../constants/messageConstants";

import axios from "axios";

export const getMessages = () => async (dispatch) => {
    const { data } = await axios.get("/api/messages");
    dispatch({
        type: actionTypes.GET_MESSAGES_REQUEST,
        payload: data,
    })
}

export const saveAttributeToCatDoc = (key, val, messageChoosen) => async (dispatch, getState) => {
   const { data } = await axios.post("/api/messages/attr", { key, val, messageChoosen }); 
   if (data.messageUpdated) {
       dispatch({
           type: actionTypes.SAVE_ATTR,
           payload: [...data.messageUpdated],
       })
   }
}

export const newMessage = (message) => async (dispatch, getState) => {
    const res = getState().getMessages.messages;
    const { data } = await axios.post("/api/messages", { message });
    if (data.messageCreated) {
        dispatch({
            type: actionTypes.INSERT_MESSAGE,
            payload: [...res, data.messageCreated],
        })
    }
}

export const deleteMessage = (message) => async (dispatch, getState) => {
    const res = getState().getMessages.messages;
    const messages = res.filter((item) => item.name !== message);
    const { data } = await axios.delete("/api/messages/" + encodeURIComponent(message));
    if (data.messageDeleted) {
        dispatch({
           type: actionTypes.DELETE_MESSAGE, 
           payload: [...messages],
        })
    }
}