import EditMessagePageComponent from "./components/EditMessagePageComponent";
import axios from "axios";

const fetchMessage = async (messageId) => {
    const { data } = await axios.get(`/api/messages/${messageId}`);
    return data;
}

const updateMessageApiRequest = async (id, title, name, surname, telephone, email, address, code, color, image) => {
    const { data } = await axios.put(`/api/messages/${id}`, { title, name, surname, telephone, email, address, code, color, image });
    return data;
}

const AdminEditMessagePage = () => {
  
  return <EditMessagePageComponent updateMessageApiRequest={updateMessageApiRequest} fetchMessage={fetchMessage} />;
};


export default AdminEditMessagePage;
