import { Row, Col, Table, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import { useState, useEffect } from "react";
import { logout } from "../../../redux/actions/userActions";
import { useDispatch } from "react-redux";

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ArticleIcon from '@mui/icons-material/Article';
import { Redirect, Link } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { strDateITAfromDayJs, strDateUSAfromDayJs, strDateITAfromStrUSA, strDateUSAfromStrITA } from '../../../utils/dateUtils.js'
const { strCurrency, strEuroCurrency } = require('../../../utils/currencyUtils.js')

const AdminMessagesPageComponent = ({ fetchMessages, deleteMessage }) => {
  const [messages, setMessages] = useState([]);
  const [messageDeleted, setMessageDeleted] = useState(false); 
  const dispatch = useDispatch();

  const [finalClickInfo, setFinalClickInfo] = useState(null);

// --- INI -------  Confirm Dialog
const [idMessageInWorking, setIdMessageInWorking] = useState("");
const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
const [open, setOpen] = React.useState(false);

const handleClickOpen = () => {
  setOpen(true);
};

const PopupConfirmConfirm = async() => {
  console.log('[PopupConfirmConfirm]')
  const data = await deleteMessage(idMessageInWorking)
  if (data.message === "message removed") {
      setMessageDeleted(!messageDeleted);
  }
  window.location.assign('/admin/messages')
  setOpen(false);
  window.location.assign('/admin/messages')
};

const PopupConfirmClose = () => {
  console.log('[PopupConfirmClose]')
  setOpen(false);
};

const ViewConfirmDialog = () => {
  return (
    <Dialog
    open={open}
    onClose={PopupConfirmClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle 
        id="alert-dialog-title"
        style={{background: "#4bf5cc", fontWeight:"bold"}}>
        {"Cancellazione risorsa"}
    </DialogTitle>

    <DialogContent>
        <DialogContentText id="alert-dialog-description" >
            &nbsp;
        </DialogContentText>
        <DialogContentText id="alert-dialog-description" >
          Confermi la cancellazione della risorsa selezionata? 
         </DialogContentText>
         <DialogContentText id="alert-dialog-description" >
            &nbsp;
        </DialogContentText>
    </DialogContent>

    <DialogActions>
      <Button 
        className="btn btn-warning"
        onClick={PopupConfirmClose}>
          Annulla
      </Button>
      <Button
        className="btn btn-primary" 
        onClick={PopupConfirmConfirm}
        autoFocus
      >
        Conferma
      </Button>
    </DialogActions>

  </Dialog>
  );
};
// --- FIN -------  Confirm Dialog

  const handleOnCellClick = (params) => {
    setFinalClickInfo(params);
    console.log('onclick params=',params)
    console.log('onclick params riga =',params.id)
    console.log('onclick params titoloColonna =',params.field)
  };
  
  const deleteHandler = async (messageId) => {
    if (window.confirm("Sei sicuro?")) {
        const data = await deleteMessage(messageId)
        if (data.message === "message removed") {
            setMessageDeleted(!messageDeleted);
        }
        //navigate("/admin/messages");
        window.location.assign('/admin/messages')
    }
  };

  const paginationModel = { page: 0, pageSize: 5 };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          slotProps={{ tooltip: { title: 'visualizza colonne' } }}
        />
        <GridToolbarFilterButton
          slotProps={{ tooltip: { title: 'Imposta filtri' } }}
        />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Cambia densità' } }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Esporta dati' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: 'title', headerName: 'nome cognome', width: 250, headerAlign: 'center'},
    { field: 'email', headerName: 'email', width: 200, headerAlign: 'center' },
    { field: 'telephone', headerName: 'telefono', width: 120, headerAlign: 'center'},
    { field: 'messageTxt', headerName: 'messaggio', width: 400, headerAlign: 'center' },
    {
      field: "actions",
      type: "actions",
      headerName: 'Azioni',
      hide: true,
      width: 100,
      getActions: (params: any) => [
        <GridActionsCellItem 
          icon={<ArticleIcon />} 
          label="Edit" 
          component={Link}
          to={`/admin/edit-message/${params.id}`}
        />,
        <GridActionsCellItem 
          icon={<DeleteIcon />} 
          label="Delete" 
          onClick={() => {
            //deleteHandler(params.id)
            setIdMessageInWorking(params.id)
            handleClickOpen();
          }}
        />
      ]
    },
    { field: 'day', headerName: 'Data', width: 100, headerAlign: 'center', aligh:"right",
      valueGetter: (params) => 
        {
          console.log({ params });
          return strDateITAfromStrUSA(params)
        },    
    },
    { field: 'time', headerName: 'Ora', width: 100, headerAlign: 'center' },
  ];


  useEffect(() => {
    const abctrl = new AbortController();
    fetchMessages(abctrl)
      .then((res) => setMessages(res))
      .catch((er) =>
      dispatch(logout())
        // setMessages([
        //   {name: er.response.data.message ? er.response.data.message : er.response.data}
        // ])
      );
    return () => abctrl.abort();
  }, [messageDeleted]);

  return (

    <Row className="m-5">

      <Col md={2}>
        <AdminLinksComponent />
      </Col>
      
      <Col md={10}>
        <h1>
          Messaggi ricevuti {" "} 
        </h1>


       <Paper sx={{ height: 430, width: '100%' }}>
          <DataGrid
            getRowId={(row) => row._id}
            rows={messages}
            columns={columns}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[5, 10, 15, 20]}
            slots={{
              toolbar: CustomToolbar,
            }} 
            slotProps={{
              pagination: {
                labelRowsPerPage: "righe:",               
              },
            }}
            onCellClick={handleOnCellClick}
            sx={{
                  '& .MuiDataGrid-columnHeader': 
                  {
                    backgroundColor: "cyan",
                    color: "black",
                    border: 2,
                    boxShadow: 1,
                    fontWeight: 700,                           },
                  '& .MuiDataGrid-cell': 
                  {
                    backgroundColor: "lightcyan",
                    color: "gray",
                    border: 1,
                    boxShadow: 1,
                    fontWeight: 300,
                  },
                  '& .MuiDataGrid-cell:hover':
                  {
                    backgroundColor: "#b0c0e5",
                    color: 'primary.main',              
                  },     
                  '&.Mui-selected':                          
                  {
                    color: 'primary.main',              
                  }     

            }} />
        </Paper>

        < ViewConfirmDialog />

        </Col>
    </Row>
  );
};

export default AdminMessagesPageComponent;
