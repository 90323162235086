import {
  Row,
  Col,
  Container,
  Form,
  Button,
} from "react-bootstrap";
import React, { useState, useRef } from "react";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const CreateMessagePageComponent = ({
  createMessageApiRequest,
  reduxDispatch,
  newMessage,
  deleteMessage,
  saveAttributeToCatDoc
}) => {

  const [validated, setValidated] = useState(false);
  const [createProductResponseState, setCreateMessageResponseState] = useState({
    message: "",
    error: "",
  });

  const [title, setTitle] = useState("");                      
  const [name, setName] = useState("");                      
  const [surname, setSurname] = useState("");                      
  const [telephone, setTelephone] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [address, setAddress] = useState("");                      
  const [code, setCode] = useState("");                      
  const [color, setColor] = useState("");                      
  const [image, setImage] = useState("");                      

  function createNewMessage (eventKey) {

    eventKey.preventDefault();
    eventKey.stopPropagation();

    console.log(`title dig =`, title);  
    console.log(`name dig =`, name);  
    console.log(`surname dig =`, surname);  
    console.log(`telephone dig =`, telephone);  
    console.log(`email dig =`, email);  
    console.log(`address dig =`, address);  
    console.log(`code dig =`, code);  
    console.log(`color dig =`, color);  
    console.log(`image dig =`, image);  

    const formInputs = {
      title: title,
      name: name,
      surname: surname,
      telephone: telephone,
      email: email,
      address:address,
      code: code,
      color: color,
      image: image,
    };

    createMessageApiRequest(formInputs);
    window.location.assign('/admin/messages')
  }

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    window.location.assign('/admin/messages')
  }

  return (

   <Container fullWidth>
      
      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Crea nuova risorsa</h1>

          <Form noValidate validated={validated} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="title" label="Titolo" variant="outlined" defaultValue={title}
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
              />

              <TextField id="id-name" label="Nome" variant="outlined" defaultValue={name}
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField id="id-surname" label="Cognome" variant="outlined" defaultValue={surname} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setSurname(event.target.value);
                }}
              />

              <TextField id="id-telephone" label="Telefono" variant="outlined" defaultValue={telephone} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setTelephone(event.target.value);
                }}
              />

              <TextField id="id-email" label="Email" variant="outlined" defaultValue={email} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />

              <TextField id="id-address" label="Indirizzo" variant="outlined" defaultValue={address} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setAddress(event.target.value);
                }}
              />

              <TextField id="id-code" label="Codice" variant="outlined" defaultValue={code} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCode(event.target.value);
                }}
              />

              <TextField id="id-color" label="Colore" variant="outlined" defaultValue={color} type="color"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setColor(event.target.value);
                }}
              />

              <TextField id="id-image" label="Immagine" variant="outlined" defaultValue={image} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setImage(event.target.value);
                }}
              />
           
          </Box>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
        <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => {gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

          <Col md={1}>
            <Button 
              type="button"
              className="btn btn-primary"
              onClick={(e) => {createNewMessage(e); }}
              style={{
                width: "80px",
              }}
            >
              Crea
            </Button>             

            {createProductResponseState.error ?? ""}
          </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>  );
};

export default CreateMessagePageComponent;
