import * as actionTypes from "../constants/messageConstants";

export const getMessagesReducer = (state = { messages: [] }, action) => {
    switch (action.type) {
        case actionTypes.GET_MESSAGES_REQUEST:
           return {
               ...state,
               messages: action.payload,
           }
        case actionTypes.SAVE_ATTR:
            return {
                ...state,
                messages: action.payload,
            }
        case actionTypes.INSERT_MESSAGE:
            return {
                ...state,
                messages: action.payload,
            }
        case actionTypes.DELETE_MESSAGE:
            return {
               ...state,
               messages: action.payload,
            }
        default:
            return state;
    }
}